import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Video } from '@frontend/core';
import { of } from 'rxjs/internal/observable/of';
import { VimeoMeta } from './vimeo-meta.model';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map } from 'rxjs/internal/operators/map';

type VideoMetaDataItem = {
  video:Video,
  metaData: VimeoMeta
}

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  _vimeo_synchronous_thumbnail_base_url = "https://vumbnail.com/";
  vimeo_api_url = "http://vimeo.com/api/v2/video/"; // http://vimeo.com/api/v2/video/[video_id].[format e.g. PHP or JSON]
  // _fallback_thumbnail_url = // use fallback_scene_url from Media Service if thumbnail is missing;
  videoMetaDataItems : VideoMetaDataItem[] = [];

  constructor(private http: HttpClient) { }

  // getPlaceholder () : Video {
  //     return {name:'Kathryn Libioulle-Clutz, toolbox tool: favourite shoe', id: 10000, host_identifier: '558020191', host_params: 'h=be064152b1', host: 'vimeo'}
  // }

  get vimeo_synchronous_thumbnail_base_url (){
    return this._vimeo_synchronous_thumbnail_base_url;
  }

  getVideoThumbnailSynchronously (video:Video, size?:string) : string {
    if(!video){return null};
    if(!['small','medium','large'].includes(size)){size = 'medium'};
    if(video.host === 'vimeo'){
      return this._vimeo_synchronous_thumbnail_base_url+video.host_identifier+(size === 'small' || size === 'medium' ? '_'+size : '')+'.jpg';
    }
  }
  customiseThumbnailSize(video:Video, metaData : VimeoMeta, width?: number, height?:number, format? : string) : string {
    let url = '';
    if(video.host === 'vimeo' && metaData){
      const lastIndex = metaData.thumbnail_large.lastIndexOf('d_');
      if (lastIndex !== -1) {
        const display = 'd_' + width + (height ? 'x' + height : '') + '.' + format;
        url = metaData.thumbnail_large.substring(0, lastIndex) + display;
      }
    }
    return url;
  }
  cacheVideoMeta (video:Video, metaData : VimeoMeta){
    const item : VideoMetaDataItem = {video:video,metaData:metaData};
    const foundIndex = this.videoMetaDataItems.findIndex(metaDataItem =>metaDataItem.video?.id === video.id);
    if(foundIndex > -1){
      this.videoMetaDataItems[foundIndex] = item;
      } else {
      this.videoMetaDataItems.push(item);
    }
  }
  getVideoThumbnailAsynchronously (video:Video, width: number = 200, height?:number, format : string = 'jpg') {

    const cachedvideoMetaData = this.videoMetaDataItems.find(metaDataItem=>metaDataItem.video.id === video.id)?.metaData;

    if (cachedvideoMetaData) {
      return of(this.customiseThumbnailSize(video,cachedvideoMetaData, width,height,format));
    }

    return this.http.get<{data:VimeoMeta}>(this.vimeo_api_url+video.host_identifier+'.json').pipe(
      catchError(this.handleError),
      map((responseData) => {
        debugger;
        this.cacheVideoMeta(video,responseData.data);
        return this.customiseThumbnailSize(video,responseData.data, width,height,format)
      })
    );
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // switch (errorResponse.error.message) {
    //   case 'This data given was invalid.':
    //     errorMessage = 'error.data_invalid';
    //     break;
    //   case 'This action is unauthorized.':
    //     errorMessage = 'error.permissions_lacking';
    //     break;
    //   default :
    //     // no action needed. We already set the default error message.
    // }
    // if (errorResponse.error.meta){
    //   return throwError({message:errorMessage,meta:errorResponse.error.meta});
    // }
    return throwError(errorMessage);
  }

}