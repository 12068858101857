import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { TrackingService } from '../tracking/tracking.service';
import { RatingService } from '@frontend/shared';
import { BrandDisplayService, BrandEditingService } from '../branding';
import { HubService } from '../hubs';
import { NotificationService } from '../notifications';
import { SnapshotService, SurveysService } from '../surveys';
import { FormCachingService } from '../utilities';


@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private http: HttpClient,
    private authService : AuthService,
    private router : Router,
    private trackingService : TrackingService,
    private ratingService : RatingService,
    private brandEditingService: BrandEditingService,
    private brandDisplayService: BrandDisplayService,
    private hubService: HubService,
    private notificationService: NotificationService,
    private snapshotService: SnapshotService,
    private surveysService: SurveysService,
    private formCachingService: FormCachingService,
  ) { }

  logout() {
    return this.http.post<any>('api/v1/frontend/logout', {}).pipe(
      catchError(this.handleError),
      tap(() => {
        this.authService.removeCachedUser();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.trackingService.clearData();
        this.brandEditingService.clearData();
        this.brandDisplayService.clearData();
        this.ratingService.clearData();
        this.hubService.clearData();
        this.notificationService.clearData();
        this.snapshotService.clearData();
        this.surveysService.clearData();
        this.formCachingService.clearData();
        this.router.navigateByUrl('/home');
      }),
      catchError(error=>this.handleError(error)),
    );
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    } else {
      return throwError(errorResponse.error.message);
    }
  }
}
