import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultisiteFooterComponent } from './multisite-footer/multisite-footer.component';
import { TranslocoModule } from '@jsverse/transloco';
import { WebsitesModule } from '../sites';
import { GuestImportModule, GuestLoginModule } from '@frontend/shared';



@NgModule({
  declarations: [
    MultisiteFooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    WebsitesModule,
    GuestImportModule,
    GuestLoginModule,
  ],
  exports: [
    MultisiteFooterComponent
  ]
})
export class FooterModule { }
