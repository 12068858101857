import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserConnectionsDetailsUpdateComponent } from './user-connections-details-update/user-connections-details-update.component';
import { UserConnectionsHomeComponent } from './user-connections-home/user-connections-home.component';

const routes: Routes = [
  {
    path: '',
    component: UserConnectionsHomeComponent,
    children : [
      { path: 'raters', component: UserConnectionsDetailsUpdateComponent},
      { path: '', redirectTo: 'raters', pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class UserConnectionsRoutingModule { }
