import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';

import { map, catchError, switchMap } from 'rxjs/operators';

import { Observable, of, Subscription, throwError } from 'rxjs';

import { Snapshot } from './snapshot.model';
import { LanguageService } from '../../language';

import { CloudinaryMediaAsset } from '../../models'
import { MediaService } from '../../content';
import { Culture } from '../../cultures';
// import { ApplicationService } from '../../utilities';
import { AuthService } from '../../auth';


interface SnapshotsListingResponse {
  data : Snapshot[]
}
export interface SnapshotRequestData {
  cultures : Culture[],
  title : string,
  description : string,
  primary_culture_id?: number,
}

@Injectable({
  providedIn: 'root'
})
export class SnapshotService {

  snapshots : Snapshot[] = [];
  allSnapshotsCached: Date = null; // checks whether we have ever requested all the Snapshots from the backend
  activeLanguageSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private mediaService: MediaService,
    private languageService: LanguageService,
    // private applicationService: ApplicationService,
    private authService: AuthService,
    @Inject('appKey') private appKey:string,
  ) { 
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations() {
    this.snapshots = [];
  }
  clearData() {
    this.clearTranslations();
    this.snapshots = [];
    this.allSnapshotsCached = null;
  }

  getCachedSnapshot (id: number){
    return this.snapshots.find(s=>s.id === id);
  }
  cacheSnapshots (snapshots: Snapshot[]){
    snapshots.forEach(c=>{
      this.cacheSnapshot(c);
    });
    this.allSnapshotsCached = new Date();
  };
  cacheSnapshot (snapshot: Snapshot){
    let cachedSnapshotIndex = this.snapshots.findIndex(c=>c.id === snapshot.id);
    if (cachedSnapshotIndex >-1){
      this.snapshots[cachedSnapshotIndex] = snapshot;
    } else {
      this.snapshots.push(snapshot);
    }
  };
  transformSnapshot (snapshotResponse) : Snapshot {
    let snapshotWithoutExtraData = {...snapshotResponse};
    delete snapshotWithoutExtraData.metaTexts;
    let snapshot : Snapshot = snapshotWithoutExtraData;
    snapshot.title = !snapshot.title && snapshot.label ? snapshot.label : snapshot.title;
    snapshot.description = !snapshot.description && snapshot.info ? snapshot.info : snapshot.description;
    snapshot.date = new Date(snapshotResponse.expired_at);
    if (snapshotResponse.media?.length){
      // let additional_media : CloudinaryMediaAsset[] = [];
      let media : CloudinaryMediaAsset;
      snapshotResponse.media.forEach(item => {
        if (item.category==='profile' && item.type ==='banner'){
          media = Object.assign(item, this.mediaService.setupCloudinaryImageMediaUrls(item));
        // } else {
        //   additional_media.push(Object.assign(item, this.mediaService.setupCloudinaryImageMediaUrls(item)));
        } 
      });
      snapshot.media = media;
    } else {
      snapshot.media = null
    };
    snapshot.primary_culture_id = snapshot.cultures.find(c=>c.pivot.type === 'primary')?.id
    return snapshot;
  };
  transformSnapshots (snapshots){
    let transformedSnapshots = [];
    snapshots.forEach(s=>{
      transformedSnapshots.push(this.transformSnapshot(s));
    });
    return transformedSnapshots;
  }
  storeNewSnapshot(snapshotData: SnapshotRequestData) {
    // let appKey = process.env['NX_APP_KEY']; // this also works: this.applicationService.getAppKey();
    let appKey = this.appKey;
    debugger;
    // let payload = Object.assign({site:this.applicationService.getDomain(),snapshotData});
    let payload = new FormData();
    payload.append('title',snapshotData.title);
    payload.append('description',snapshotData.description);
    payload.append('culture_ids',snapshotData.cultures.map(c=>c.id).toString());
    payload.append('site',appKey);
    if (snapshotData.primary_culture_id){
      payload.append('primary_culture_id',snapshotData.primary_culture_id.toString());
    }
    let url = this.addGuestToUrl('api/v1/snapshots');

    return this.http.post<{ data: Snapshot }>(url, payload).pipe(
      map((response) => {
        let snapshot: Snapshot = this.transformSnapshot(response.data);
        this.cacheSnapshot(snapshot);
        return snapshot;
      }),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }
  updateSnapshot(snapshot : Snapshot, snapshotData: SnapshotRequestData) {
    // let payload = Object.assign({site:this.applicationService.getDomain(),snapshotData});
    let primary_culture_id = snapshotData.primary_culture_id; // || snapshot.cultures.find(c=>c.pivot.type === 'primary')?.id;
    let payload = new FormData();
    payload.append('title',snapshotData.title);
    payload.append('description',snapshotData.description);
    payload.append('culture_ids',snapshotData.cultures.map(c=>c.id).toString());
    if (primary_culture_id){
      payload.append('primary_culture_id',primary_culture_id.toString());
    }
    
    let url = this.addGuestToUrl('api/v1/snapshots/update/'+snapshot.id);

    return this.http.post<{ data: Snapshot }>(url, payload).pipe(
      map((response) => {
        let snapshot: Snapshot = this.transformSnapshot(response.data);
        this.cacheSnapshot(snapshot);
        return snapshot;
      }),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  }
  getSnapshots (freshFromServer: boolean){
    if(!freshFromServer && this.allSnapshotsCached){
      return of(this.snapshots)
    }
    let url = this.addGuestToUrl('api/v1/snapshots')
    return this.http.get<SnapshotsListingResponse>(url)
      .pipe(
        map(response =>{
          if (response ){
            let snapshots = this.transformSnapshots(response.data);
            this.cacheSnapshots(snapshots);
            return snapshots;
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  }

  acceptSnapshot (id : number){
    if (!id){alert('Check the id please');};

    let url = this.addGuestToUrl('api/v1/snapshots/accept/'+id);
    
    return this.http.get<SnapshotsListingResponse>(url)
      .pipe(
        map(response =>{
          if (response ){
            let snapshots = this.transformSnapshots(response.data);
            this.cacheSnapshots(snapshots);
            return this.snapshots.find(s=>s.current_accepted);
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  };
  getSnapshot (id : number, freshFromServer: boolean){
    if (!id){alert('Check the id first');};
    let cachedSnapshot : Snapshot = this.getCachedSnapshot(id);
    if (cachedSnapshot && !freshFromServer){
      return of(cachedSnapshot);
    };
    let url = this.addGuestToUrl('api/v1/snapshots/'+id);
    
    return this.http.get<{data: Snapshot[]}>(url)
        .pipe(
          map(response =>{
            if (response && response.data ){
              let snapshot : Snapshot =  this.transformSnapshot(response.data)
              this.cacheSnapshot(snapshot);
              return snapshot;
            };
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  findBlockingSnapshot(currentSnapshot): Observable<Snapshot> { // if some other snapshot is currently accepted, return that
    if (!currentSnapshot || currentSnapshot.current_accepted) {
      return of(null);
    }
    return this.getCurrentAcceptedSnapshot(false).pipe(
      switchMap((snapshot) => {
        if (snapshot && currentSnapshot.id !== snapshot.id) {
          // alert('You have another snapshot in progress');
          return of(snapshot);
        } else {
          return of(null);
        }
      }),
      catchError((error) => {
        // return of(false)) 
        return this.handleError(error);
      })
    );
  }
  getCurrentAcceptedSnapshot (freshFromServer: boolean){
    let cachedSnapshot : Snapshot = this.snapshots.find(s=>s.current_accepted);
    if (cachedSnapshot && !freshFromServer && this.snapshots.length){
      return of(cachedSnapshot);
    };
    
    return this.getSnapshots(freshFromServer)
        .pipe(
          map(response =>{
            return this.snapshots.find(s=>s.current_accepted);
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  cancelSnapshot (id : number){
    if (!id){alert('Check the id, please');};

    let url = this.addGuestToUrl('api/v1/snapshots/cancel/'+id);

    return this.http.get<{data: Snapshot[]}>(url)
        .pipe(
          map(response =>{
            if (response?.data){
              this.snapshots = this.snapshots.filter(s=>s.id!==id);
              return true;
            }
          }),
          catchError((error) => {
            return this.handleError(error);
          })
        )
  };
  endSnapshot (id : number){
    if (!id){alert('Check the id');};

    let url = this.addGuestToUrl('api/v1/snapshots/end/'+id);
    
    return this.http.get<SnapshotsListingResponse>(url)
      .pipe(
        map(response =>{
          if (response ){
            let snapshots = this.transformSnapshots(response.data);
            this.cacheSnapshots(snapshots);
            return this.snapshots.find(s=>s.current_accepted);
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  };
  uploadFile(id: number, formData: FormData) {
    return this.http
      .post('/api/v1/snapshots/upload-media/' + id, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((response) => {
          if (response.type === HttpEventType.Response) {
            const updatedData = response.body as {
              snapshot: Snapshot;
              media: CloudinaryMediaAsset;
            };
            if (updatedData.snapshot) {
              const snapshot: Snapshot = this.transformSnapshot(updatedData.snapshot);
              this.cacheSnapshot(snapshot);
            }
          }
          return response;
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      );
  }
  private addGuestToUrl(url):string{
    // use this function if 'guest' will be the only URL parameter
    let user = this.authService.user.getValue();
    let guest = this.authService.guest.getValue();
    if (!user && guest){
      return url+='?guest_uuid='+guest.uuid;
    };
    return url;
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
    //   errorMessage ="content_management.slug_availability_error";
    //   return throwError(errorMessage);
    // }
    switch (errorResponse.error.message) {
      case 'This data given was invalid.':
        errorMessage = 'error.data_invalid';
        break;
      case 'This action is unauthorized.':
        errorMessage = 'error.permissions_lacking';
        break;
      case 'Editing this Snapshot not allowed':
        errorMessage = 'error.permissions_lacking';
        break;
      case 'Access denied to this Snapshot':
        errorMessage = 'error.permissions_lacking_view';
        break;
      case 'You have another snapshot accepted.':
        errorMessage = 'survey.other_snapshot_active';
        break;
      case 'Snapshot not available for ending or cancelling.':
        errorMessage = 'survey.snapshot_cannot_end_or_cancel';
        break;
      case 'Snapshot not available for ending because it is not complete.':
        errorMessage = 'survey.snapshot_cannot_end_when_incomplete';
        break;
      default :
        // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }

}
