import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseHomeComponent } from './course-home/course-home.component';
import { LessonComponent } from './lesson/lesson.component';
import { CourseListPromoComponent } from './list-promo/course-list-promo.component';

const routes: Routes = [
  {
    path: '',
    data: {
      className: 'courses',
      // label: 'Course homepage',
      // translationKey: 'navigation.courses',
      // labelSource: null
    },
    component: CourseListPromoComponent,
  },
  {
    path: ':course',
    data: { label: 'Course homepage', translationKey: null, labelSource: 'titleService'},
    children: [
      {
        path: '',
        // pathMatch : 'full',
        component: CourseHomeComponent,
      },
      {
        path: ':lesson',
        component: LessonComponent,
        data: { label: 'Lesson', translationKey: null, labelSource: 'titleService',
        },
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoursesRoutingModule {}
