<div class="container">
  <div class="sidenav-second-level my-5">
    <div class="row gap-4 gap-lg-0">
      <div class="col-lg-4">
        <multisite-vertical-menu-type-one
        [navigationLinks]="navigationLinks"
        [pictureEditable]="false"
        [nameText]="user ? user.fname+' '+user.lname : '[No name]'"
        [picture]="user?.picture ? user.picture : getDefaultAvatar()"
        >
        </multisite-vertical-menu-type-one>
      </div>
      <div class="col-lg-8">

       <user-account-details-update
       [user]="user"
       (changeEmail)="changeEmail($event)"
       [loading]="loading"
       [error]="error"
        >
        </user-account-details-update>
        
      </div>
    </div>
  </div>
</div>