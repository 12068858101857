import { Icon } from "@frontend/shared";
import { CloudinaryMediaAsset } from "../../models";
import { Course } from "../../courses/course.model";

export class Topic {
    id : number;
    name : string;
    description : string;
    slug : string;
    category : string;
    type : string;
    // reviews: any; // TODO !!!
    created_at: string;
    updated_at: string;
    courseSlugs?: string[];
    courses?: Course[];
    icon?: Icon;
    media?: CloudinaryMediaAsset[]

}