import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { forkJoin, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Culture, Cluster, DataProcessingService, LanguageService, MediaService } from '@frontend/common';
import { GeoJsonFeature } from '../maps';
import { GeoJsonFeatureGeometry } from '../maps/geojson-feature-geometry.model';
import { ClusterService } from '../clusters/cluster.service';
import { CultureClusterIdentifier } from '../clusters/culture-cluster-identifier.model';
import { CultureClusterWrapper } from '../clusters/culture-cluster-wrapper.model';
import { Tag } from '@frontend/core';

type GeoJsonResponseCulture = {
  properties: {    
    identifier : any, // iso_3166_2 in the case of cultures,
    className : string, // 'Culture', name of the model in the backend
    category : string, // 'geographic' // same as the culture's category
    type : string, // 'national' // same as the culture's type
    geometry_type : string, // 'geometry_110'
    displayOverDateLineInFarEast : boolean, // for small islands we want to display with the rest of Polynesia, not split by the international dateline
  },
  geometry: GeoJsonFeatureGeometry,
  geometry_meta? : any
}
export type CultureWrapperWithProperties = {
  properties: {
    culture: Culture,
    [key:string]: any
  },
}

@Injectable({
  providedIn: 'root',
})
export class CultureService {
  activeLanguageSubscription: Subscription;
  cultures: Culture[] = [];
  culturesGeoJson : GeoJsonFeature[] = [];

  constructor(
    private languageService: LanguageService,
    private clusterService: ClusterService,
    private http: HttpClient,
    private dataProcessingService : DataProcessingService,
    private mediaService : MediaService,
  ) 
  {
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
  }

  clearTranslations() {
    this.cultures = [];
    this.culturesGeoJson = this.culturesGeoJson.map(feature => {
      return {
        ...feature,
        properties: {
            ...feature.properties,
            culture: null
        }
      };
    })
  };
  cacheCultures(cultures:Culture[]){
  
    if (this.cultures.length){
      cultures = this.dataProcessingService.mergeTwoArraysWithUniqueIdentifiersAvoidingDuplicates(cultures,this.cultures,'id');
    }
    
    this.cultures = cultures.sort((a, b) => a.name.localeCompare(b.name));

  }
  convertCultureToTag (culture : Culture, preserveId : boolean, flagTransformations = 'w_32,c_fill,ar_1:1,r_max,f_auto/'){
    return new Tag(
      preserveId ? culture.id : null,
      culture.name,
      culture.slug,
      'culture',
      culture.flag?.emoji ? 'emoji' : culture.flag?.hash ? 'cloudinary_flag' : '',
      culture.flag?.emoji ?? culture.flag?.hash ?? '',
      culture.flag?.hash ? this.mediaService.getFlagUrlFromHash(culture.flag.hash,'',true,'.png') : null
    )
  }
  convertCulturesToTags (cultures : Culture[], preserveId : boolean = false, flagTransformations? : string){
    if(!cultures.length){return [];};
    return cultures.map(c => this.convertCultureToTag(c,preserveId,flagTransformations));
  }
  transformGeoJsonFeature (itemFromBackend : GeoJsonResponseCulture) : GeoJsonFeature {

    return {
      properties : {...itemFromBackend.properties, name: null, culture: null, /* TODO  bounding box etc from itemFromBackend.geometry_meta */},
      type: "Feature",
      geometry: itemFromBackend.geometry
  }

  }
  transformGeoJsonArray (arrayFromBackend : GeoJsonResponseCulture[]) : GeoJsonFeature[]{
    let transformedCulturesGeoJson : GeoJsonFeature[] = arrayFromBackend.map(cgj=>{
      return this.transformGeoJsonFeature(cgj)
    });
    return transformedCulturesGeoJson;
  }

  cacheGeoJson(culturesGeoJson:GeoJsonFeature[]){
  
    if (this.culturesGeoJson.length){
      culturesGeoJson.forEach(newCgj => {
        let foundIndex = this.culturesGeoJson.findIndex(oldCgj => 
          oldCgj.properties.category === newCgj.properties.category &&
          oldCgj.properties.type === newCgj.properties.type &&
          oldCgj.properties.geometry_type === newCgj.properties.geometry_type
          );
        if (foundIndex >-1){
          this.culturesGeoJson[foundIndex] = newCgj;
        } else {
          this.culturesGeoJson.push(newCgj);
        }
        
      });
    } else {
      this.culturesGeoJson = culturesGeoJson;
    }

  }

  async cacheGeoJsonInCacheStorage(culturesGeoJson: GeoJsonFeature[]): Promise<void> {
    try {
      const cache = await caches.open('geojson-cache');
      const response = new Response(JSON.stringify(culturesGeoJson));
      await cache.put('cultures-geojson', response);
    } catch (error) {
      console.error('Error caching GeoJSON data:', error);
    }
  }

  async getCachedGeoJsonFromCacheStorage(): Promise<GeoJsonFeature[]> {
    try {
      const cache = await caches.open('geojson-cache');
      const responsePromise = cache.match('cultures-geojson'); // Don't await here
      const response = await responsePromise; // Await the response outside
      if (response) {
        const json = await response.json();
        return json as GeoJsonFeature[];
      }
    } catch (error) {
      console.error('Error retrieving cached GeoJSON data:', error);
    }
    return []; // Return empty array if no data found or an error occurred
  }

  private filterStoredGeoJson(culturesGeoJson : GeoJsonFeature[], category : string,type : string, geometry_type : string) {

    let filteredGeoJsonCultures : GeoJsonFeature[] = [];

    if (category !== null && type !== null && geometry_type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === category && feature.properties.type === type && feature.properties.geometry_type === geometry_type);

    } else if (category !== null && type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === category && feature.properties.type === type && feature.properties.geometry_type === null);

    } else if (category !== null && geometry_type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === category && feature.properties.type === null && feature.properties.geometry_type === geometry_type);

    } else if (type !== null && geometry_type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === null && feature.properties.type === type && feature.properties.geometry_type === geometry_type);

    } else if (category !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === category && feature.properties.type === null && feature.properties.geometry_type === null);

    } else if (type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === null && feature.properties.type === type && feature.properties.geometry_type === null);

    } else if (geometry_type !== null) {
      filteredGeoJsonCultures = culturesGeoJson.filter(feature => feature.properties.category === null && feature.properties.type === null && feature.properties.geometry_type === geometry_type);
    }
    return filteredGeoJsonCultures;
  }

  async getGeoJson(category: string, type: string, geometry_type: string, freshFromServer: boolean): Promise<GeoJsonFeature[]> {
    const geoJsonFromCache: GeoJsonFeature[] = await this.getCachedGeoJsonFromCacheStorage();
  
    let filteredGeoJsonCultures: GeoJsonFeature[] = [];
  
    if (geoJsonFromCache?.length) {
      filteredGeoJsonCultures = this.filterStoredGeoJson(geoJsonFromCache, category, type, geometry_type);
    }
  
    if (filteredGeoJsonCultures?.length && !freshFromServer) {
      return filteredGeoJsonCultures;
    }
  
    let url = 'api/v1/cultures-geojson?';
    url = category ? url += 'category=' + category : url;
    url = type ? url += '&type=' + type : url;
    url = geometry_type ? url += '&geometry_type=' + geometry_type : url;
  
    const response = await this.http.get<{ data: GeoJsonResponseCulture[] }>(url).toPromise();
    if (response?.data?.length) {
      const transformedResponseItems: GeoJsonFeature[] = this.transformGeoJsonArray(response.data);
      this.cacheGeoJsonInCacheStorage(transformedResponseItems);
      return this.filterStoredGeoJson(transformedResponseItems, category, type, geometry_type);
    }
  }
  getClusters(category : string, type : string, freshFromServer: boolean){

    return this.clusterService.getClusters(category,type, freshFromServer);

  }
  prepareGeoJsonDataForMap(cultureWrappers:CultureWrapperWithProperties[],plainGeoJsonFeatures: GeoJsonFeature[]){
    if (!cultureWrappers || !plainGeoJsonFeatures){return []};
    let availableCultureIdentifiers = cultureWrappers.map(c=>c.properties.culture?.iso);


    return plainGeoJsonFeatures.filter(f=>availableCultureIdentifiers.includes(f.properties.identifier) && f.properties.className === 'Culture')
      .map(feature => {
        const copiedFeature = { ...feature };
      
        let copiedProperties = { ...copiedFeature.properties };
      
        const cultureWrapper = cultureWrappers.find(c=>c.properties.culture?.iso===feature.properties.identifier);

        if (cultureWrapper){

          copiedProperties = { ...copiedProperties, ...cultureWrapper.properties }

        }
      
        copiedFeature.properties = copiedProperties;
      
        return copiedFeature;
      });
      
  }
  async getCulturesAndGeoJson(category: string, type: string, geometry_type: string, freshFromServer: boolean): Promise<[Culture[], GeoJsonFeature[]]> {
    const cultures$ = this.getCultures(category, type, freshFromServer).toPromise();
    const geoJson$ = this.getGeoJson(category, type, geometry_type, freshFromServer);
    const geoJsonFeatures: GeoJsonFeature[] = await geoJson$;
  
    return [await cultures$, geoJsonFeatures];
  }
  
  prepareCultureClusters(cultures:Culture[],clusters:Cluster[],relationships:CultureClusterIdentifier[], cluster_category: string, cluster_type: string) : CultureClusterWrapper[] {
    if(!cultures?.length || !clusters?.length || !relationships?.length){return [];};
    let filteredRelationships : CultureClusterIdentifier[] = cluster_category ? relationships.filter(r=>r.category === cluster_category): relationships;
    filteredRelationships = cluster_type ? relationships.filter(r=>r.type === cluster_type) : filteredRelationships;
    return filteredRelationships.map(r => {
      return new CultureClusterWrapper (
        r.id,
        r.slug,
        r.category,
        r.type,
        cultures.
          filter(c=>r.cultures.map(ci=>ci.slug)
          .includes(c.slug))
          .sort((a, b) => a.name.localeCompare(b.name)),
        clusters.find(c=>c.id===r.id));
    }).filter(cc=>cc.cluster);

  }

  getCultureClustersData(culture_category: string, culture_type: string, cluster_category: string, cluster_type: string, freshFromServer: boolean): Observable<[Culture[], Cluster[], CultureClusterIdentifier[]]> {
    const cultures$ = this.getCultures(culture_category, culture_type, freshFromServer);
    const clusters$ = this.getClusters(cluster_category, cluster_type, freshFromServer);
    const cultureClusters$ = this.getCultureClusterRelationshipsData(cluster_category, cluster_type);
    return forkJoin([cultures$, clusters$, cultureClusters$]);
  }
  getCultures(category:string,type:string, freshFromServer:boolean) {
    let filteredCultures : Culture[] = [];
    if (category !== null && type !== null) {
      filteredCultures = this.cultures.filter(c => c.category === category && c.type === type);
    } else if (category !== null) {
      filteredCultures = this.cultures.filter(c => c.category === category && c.type === null);
    } else if (type !== null) {
      filteredCultures = this.cultures.filter(c => c.category === null && c.type === type);
    }
    if (filteredCultures?.length && !freshFromServer) {
      return of(filteredCultures); // .sort((a, b) => a.name.localeCompare(b.name)));
    }
    let url = 'api/v1/cultures?';
    url = category ? url+='category='+category:url;
    url = type ? url+='&type='+type:url;
    return this.http.get<{data:Culture[]}>(url).pipe(
      map((response) => {
        if (response?.data?.length) {
          // response.data = this.transformCultures(response.data);
          this.cacheCultures(response.data);
          return response.data;
        }
      }),
      catchError(error=>this.handleError(error))

      );
    }
    getCultureClusterRelationshipsData(category: string, type: string): Observable<CultureClusterIdentifier[]> {
      let cacheKey = category ? (type ? category + '-' + type : category) : 'culture-clusters';
      return from(caches.open('culture-clusters')).pipe(
        switchMap(cache => {
          return cache.match(cacheKey).then(async response => {
            if (response) {
              return response.json(); // as CultureClusterIdentifier[]; // Parse cached data
            } else {
              const url = `api/v1/culture-clusters?${category ? 'category=' + category : ''}${type ? '&type=' + type : ''}`;
              const responseData = await this.http.get<{ data: CultureClusterIdentifier[] }>(url).toPromise();
              // Cache the response
              await cache.put(cacheKey, new Response(JSON.stringify(responseData.data)));
              return responseData.data;
            }
          });
        }),
        catchError(error => {
          return throwError(error);
        })
      );
    }
    
    private handleError(errorResponse: HttpErrorResponse) {
      let errorMessage = 'error.something_went_wrong';
      if (!errorResponse.error || !errorResponse.error.message) {
        return throwError(errorMessage);
      }
      // if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
      //   errorMessage ="content_management.slug_availability_error";
      //   return throwError(errorMessage);
      // }
      switch (errorResponse.error.message) {
        case 'This data given was invalid.':
          errorMessage = 'error.data_invalid';
          break;
        default :
          // no action needed. We already set the default error message.
      }
      if (errorResponse.error.meta){
        return throwError({message:errorMessage,meta:errorResponse.error.meta});
      }
      return throwError(errorMessage);
    }
}
