import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AvailableLanguage,
  LanguageService,
  TipService,
  WindowService,
} from '@frontend/common';
import { ActivityLogService } from '@frontend/core';
import { ReviewsService } from '@frontend/shared';
import { Subscription } from 'rxjs';
import { Course } from '../../../courses/course.model';
import { CourseService } from '../../../courses/course.service';
import { CloudinaryMediaAsset, Tip } from '../../../models';
import { PageTitleService } from '../../../navigation/title/title.service';
import { ContentItem } from '../../content-item.model';
import { Topic } from '../topic.model';
import { TopicService } from '../topics.service';
import { AccreditationService } from '../../../accreditations/accreditation.service';
import { Accreditation } from '../../../accreditations/accreditation.model';

@Component({
  selector: 'lib-topic-learner-landing-page',
  templateUrl: './topic-learner-landing-page.component.html',
  styleUrls: ['./topic-learner-landing-page.component.scss'],
})
export class TopicLearnerLandingPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  topicSlug: string; // examples: 'culturettt' or 'icpendulum'
  loadingObject: { [key: string]: boolean } = {}; // .courses .topic
  error: string;
  topic: Topic;

  thumbnail: CloudinaryMediaAsset;
  thumbnailTransformations: string = 'w_375/';
  activeLanguageSubscription: Subscription;
  activeLanguageObject: AvailableLanguage;
  coursesSubscription: Subscription;
  loadingCourses: boolean;
  latestTips: Tip[];
  accreditations: Accreditation[];
  latestTipsAsGenericContent: ContentItem[];
  topPublicReviewsAsGenericContent: ContentItem[];
  courses: Course[];
  tipCount: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activityLogService: ActivityLogService,
    private topicService: TopicService,
    private courseService: CourseService,
    private accreditationService: AccreditationService,
    private tipService: TipService,
    private pageTitleService: PageTitleService,
    private reviewsService: ReviewsService,
    private languageService: LanguageService,
    private windowService: WindowService
  ) {}

  isAnythingLoading() {
    for (let something in this.loadingObject) {
      if (this.loadingObject[something]) {
        return true;
      }
    }
    return false;
  }

  getLatestTips(freshFromServer: boolean) {
    // by default, the backend returns the latest tips first
    this.loadingObject.tips = true;
    const tipsSubscription = this.tipService
      .getPaginatedTips(this.topicSlug, {},1, freshFromServer)
      .subscribe(
        (result) => {
          let paginatedContent = result;
          this.tipCount = paginatedContent?.meta.total;
          if (paginatedContent?.data?.length) {
            this.latestTips =
              paginatedContent.data.length > 3
                ? paginatedContent.data.slice(0, 3)
                : paginatedContent.data;
          } else {
            this.latestTips = [];
          }
          this.latestTipsAsGenericContent =
            this.tipService.makeContentItems(this.latestTips);
          this.loadingObject.tips = false;
        },
        (error) => {
          this.loadingObject.tips = false;
        }
      );
    this.subscriptions.push(tipsSubscription);
  }
  getAccreditations(topicSlug: string, freshFromBackend: boolean) {
    this.loadingObject.accreditations = true;

    const accreditationSubscription = this.accreditationService
      .getAccreditations(topicSlug, freshFromBackend)
      .subscribe(
        (response) => {
          this.accreditations = response;
          this.loadingObject.accreditations = false;
        },
        (error) => {
          this.loadingObject.accreditations = false;
        }
      );
    this.subscriptions.push(accreditationSubscription);
  }
  gotoRelation(relation: string) {
    let path = './' + relation;
    if (this[relation]?.length === 1) {
      path += '/' + this[relation][0]?.slug;
    }
    this.router.navigate([path], { relativeTo: this.route });
  }
  gotoCourses() {
    let path = './courses';
    if (this.accreditations?.length === 1) {
      path += '/' + this.accreditations[0]?.slug;
    }
    this.router.navigate([path], { relativeTo: this.route });
  }
  getCourses(topic: Topic, freshFromBackend: boolean) {
    const cachedCourses = this.courseService.getCachedCoursesBySlugs(
      this.topic.courseSlugs
    );

    if (
      topic.courseSlugs &&
      topic.courseSlugs.length === cachedCourses.length
    ) {
      this.courses = cachedCourses;
      return;
    }

    this.loadingObject.courses = true;

    const coursesSubscription = this.courseService
      .getCoursesByTopic(topic.slug, freshFromBackend)
      .subscribe(
        (response) => {
          this.courses = response;
          this.loadingObject.courses = false;
        },
        (error) => {
          this.loadingObject.courses = false;
        }
      );
    this.subscriptions.push(coursesSubscription);
  }
  gotoTip(tip: Tip) {
    this.router.navigate(['./tips/' + tip.slug], { relativeTo: this.route });
  }
  getTopic(slug: string, freshFromBackend: boolean) {
    this.loadingObject.topic = true;
    this.error = null;
    let activityLogParams = {
      model: 'topic',
      identifier: slug,
      identifier_type: 'slug',
      activity_type: 'viewed',
      properties: null,
    };
    const topicSubscription = this.topicService
      .getTopic(slug, freshFromBackend)
      .subscribe(
        (response) => {
          this.topic = response;
          this.thumbnail = this.topicService.getImage(
            this.topic?.media,
            'thumbnail'
          );
          if (!freshFromBackend) {
            this.activityLogService.log(activityLogParams);
          }
          this.pageTitleService.setTitle(this.topic.name);
          this.getLatestTips(freshFromBackend);
          this.getCourses(this.topic, freshFromBackend);
          this.getAccreditations(this.topic.slug, freshFromBackend);
          // TODO
          this.getReviews(this.topic.slug,freshFromBackend);
          this.loadingObject.topic = false;
        },
        (error) => {
          this.error = error;
          this.loadingObject.topic = false;
          this.activityLogService.log(activityLogParams);
        }
      );
    this.subscriptions.push(topicSubscription);
  }
  getReviews(topicSlug: string, freshFromBackend: boolean) {
    this.loadingObject.reviews = true;
    this.error = null;

    const reviewsSubscription = this.reviewsService
      .getReviews('topic',topicSlug,1,false, freshFromBackend)
      .subscribe(
        (response) => {
          this.topPublicReviewsAsGenericContent = this.reviewsService.makeContentItems(response.data);
          this.loadingObject.reviews = false;
        },
        (error) => {
          this.error = error;
          this.loadingObject.reviews = false;
        }
      );
    this.subscriptions.push(reviewsSubscription);
  }

  ngOnInit(): void {
    this.windowService.goToTop();

    this.topicSlug = this.route.snapshot.params['topic']?.toLowerCase();

    if (this.topicSlug) {
      this.getTopic(this.topicSlug, false);
    }

    this.activeLanguageObject = this.languageService.activeLanguageObjectSynchronously;
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(
        (newActiveLanguage) => {
          // TODO - find a better way to prevent this being called when the component initialises. It should be called only when the language changes
          if (
            newActiveLanguage?.languageKey !==
            this.activeLanguageObject.languageKey
          ) {
            this.activeLanguageObject = newActiveLanguage;
            this.getTopic(this.topicSlug, true);
          }
        }
      );
  }

  ngOnDestroy() {
    if (this.coursesSubscription) {
      this.coursesSubscription.unsubscribe();
    }
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
