import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { map, catchError, switchMap } from 'rxjs/operators';

import { Observable, of, Subscription, throwError } from 'rxjs';

import { CiResultConstants, CiResultFixedData, ContentItem, DataProcessingService, MetaText, ProgressIndicatorItem, Snapshot, SurveysService } from '@frontend/common';
import { CiResult  } from './ciresult.model';
import { LanguageService } from '@frontend/common';

import { Page, PageService } from '@frontend/core';
import { Icon } from '@frontend/shared';

type ScoreCalibration = {
  minimumScore : number, // 50 - the bottom of the range for low scores; below this (and slightly above) everything will be scored as lowest/minimum
  maximumScore : number, // 90 - the opposite of minimumScore, of course
  maximumSteps : number // 8 - the number of steps which will be equally distributed from min to max (traffic light colours support maximumSteps 9 (0 to 9 = 10 steps))
}

@Injectable({
  providedIn: 'root'
})
export class CiResultService {

  ciResults : CiResult[] = [];
  metaTexts : MetaText[] = [];
  activeLanguageSubscription: Subscription;
  surveyCompleteSubscription: Subscription;
  scoreCalibrationsByProperty : {[key:string]: ScoreCalibration} = {};
  prioritiesCountThreshold : number = 6; // if there are more than 6 priorities after iterating through in a ciResult, we will not add any more
  skipTopSteps : number = 2; // if the score is in one of the top 2 steps, we will not include it as a priority
  ciResultConstants = CiResultConstants;

  constructor(
    private http: HttpClient,
    private pageService: PageService,
    private languageService: LanguageService,
    private surveysService: SurveysService,
    private dataProcessingService: DataProcessingService,

  ) { 
    this.activeLanguageSubscription =
      this.languageService.activeLanguageObject.subscribe(() => {
        this.clearTranslations();
      });
      this.surveyCompleteSubscription = this.surveysService.surveyCompleteAlertSubject.subscribe( (surveyCompletedSlug) => {
        if (this.ciResultConstants.domains.find(d=>d.slug === surveyCompletedSlug)){
          this.ciResults = []; // force a request to the backend for new data when the user finished part (survey) of the ciResult
        }
      })
      this.scoreCalibrationsByProperty = {
        default : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        total : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        know : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        love : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        think : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        act : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        range : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        performing : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        values : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        behaviour : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        blindspots : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        resilience : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        mastery : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        preparing : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        noticing : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        reviewing : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        expecting : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        reformulating : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        authenticity : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        countering_intuition : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        stereotypes : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        translations : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        growth : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        ambassadorial : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        external : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
        internal : {minimumScore : 50, maximumScore : 85, maximumSteps : 8},
      }
  }

  clearTranslations() {
    this.clearCiResults() // because the CiResult.meta contains cultural differences (merged in by the front end)
    this.metaTexts = [];
  }
  clearData() {
    this.clearTranslations();
  }
  clearCiResults() {
    this.ciResults = [];
  }
  cacheCiResults (ciResults: CiResult[]){
    ciResults.forEach(c=>{
      this.cacheCiResult(c);
    });
  };
  cacheCiResult (ciResult: CiResult){
    let cachedCiResultIndex = this.ciResults.findIndex(c=>c.id === ciResult.id);
    if (cachedCiResultIndex >-1){
      this.ciResults[cachedCiResultIndex] = ciResult;
    } else {
      this.ciResults.push(ciResult);
    }
  };
  getCachedResultBySnapshot (snapshot_id: number){
    return this.ciResults.find(cir=>cir.snapshot_id === snapshot_id);
  }
  getCachedResults (){
    return this.ciResults;
  };
  sortByRange <T>(array: T[]): T[] {
      return array.sort((a, b) => {
        if ((a['range']?.[0] == null || a['range']?.[0] == undefined) || (b['range']?.[0] === null || b['range']?.[0] === undefined)){
          return 0;
        }
        if (a['range'] < b['range']) {
          return -1;
        } else if (a['range'] > b['range']) {
          return 1;
        } else {
          return 0;
        }
      });
  }
  getSourceContentConstant (slug : string, ciResultConstants : typeof CiResultConstants ) : CiResultFixedData{

    // TOPIC
    let sourceContent : CiResultFixedData = ciResultConstants.topics.find(t=>t.slug === slug);

    // DOMAIN
    if (!sourceContent) {
      sourceContent = ciResultConstants.domains.find(d=>d.slug === slug);
    }

    // SUBDOMAIN
    if (!sourceContent) {
      let domain = ciResultConstants.domains.find(d=>d.children.map(s => s.slug).find(s=>s===slug));
      if (domain){
        let subdomain = domain.children.find(s=>s.slug === slug);
        if(subdomain){
          sourceContent = Object.assign({},subdomain);
          sourceContent.slug = domain.slug; // we want the related domain slug not the subdomain slug, because a subdomain screen does not exist (for routing)
        }
      }
    }
    
    return sourceContent
  }
  getPrioritiesAsContentItems(ciResult : CiResult) : CiResult {

    if (!ciResult.priorities){return ciResult};
    if(!ciResult.meta) {ciResult.meta = {cco_recommendations:null,ranges_by_cultural_difference_id:null,certainty_by_domain:null}};

    ciResult.meta.priorities_as_content_items = ciResult.priorities.map(p=>{
      const sourceContent = this.getSourceContentConstant(p,this.ciResultConstants);
      return new ContentItem (null,p,sourceContent?.titleTranslationKey,null,null,null,sourceContent?.titleTranslationKey+'_description',null,sourceContent.slug,null,null,null,null,null,null,null,null,null,null,{iconClasses:sourceContent?.iconClasses});
    })

    ciResult.meta.priorities_as_content_items = ciResult.meta.priorities_as_content_items.filter(item => item); // remove any null items

    return ciResult;
  }
  generatePriorities (ciResult : CiResult) : CiResult {
    ciResult.priorities = [];
    if (ciResult?.total !== 0 && !ciResult?.total) { return ciResult; };

    for (const property in this.scoreCalibrationsByProperty) {
      if ( property !== 'total' && Object.prototype.hasOwnProperty.call(ciResult, property)) {
        const scoreCalibration : ScoreCalibration = this.scoreCalibrationsByProperty[property];
        const score : number = ciResult[property];
        if ((score === 0 || score > 0) && score < scoreCalibration.minimumScore && !ciResult.priorities.includes(property)){
          ciResult.priorities.push(property);
        }        
      }
    }
    const maxSteps = this.scoreCalibrationsByProperty?.default?.maximumSteps ?? 8;

    for (var step = 0; step < (maxSteps+1) - this.skipTopSteps; step++){

      for (const property in this.scoreCalibrationsByProperty) {
        if (property !== 'total' &&  Object.prototype.hasOwnProperty.call(ciResult, property)) {
          const scoreCalibration : ScoreCalibration = this.scoreCalibrationsByProperty[property];
          const score : number = ciResult[property];
          if (
            (score === 0 || score > 0) &&
            score < scoreCalibration.minimumScore + (((scoreCalibration.maximumScore - scoreCalibration.minimumScore) / maxSteps )*step) &&
            !ciResult.priorities.includes(property)){
            ciResult.priorities.push(property);
          }        
        }
      }
      if (ciResult.priorities.length >= this.prioritiesCountThreshold){
        break;
      }
    }
    return this.getPrioritiesAsContentItems(ciResult);
  }
  formatPrioritiesForProgessIndicatorListing(result : CiResult) : ProgressIndicatorItem[] {
    return result?.meta?.priorities_as_content_items?.length ? result.meta.priorities_as_content_items.map(p=>{
      return new ProgressIndicatorItem(
        this.getScaleSteps(p.title,result[p.slug]) ? 'traffic-light-'+this.getScaleSteps(p.title,result[p.slug]) : 'traffic-light-0',
        result[p.slug],
        new Icon(p.meta?.iconClasses),
        p.titleTranslationKey,
        null,
        p.slug
      );}) : [];
  }
  nullifyTopicsIfProfileIncomplete(ciResult : CiResult) : CiResult {
    if(ciResult && ciResult.total === null){

      let subdomainSlugs = [].concat(...this.ciResultConstants.domains.map(d => d.children.map(s => s.slug)).map(slugs => slugs)); // 'authenticity' is both a subdomain and a topic, so we cannot nullify the subdomain

      let topicSlugs = this.ciResultConstants.topics.map(t=>t.slug).filter(slug => !subdomainSlugs.includes(slug));

      topicSlugs.forEach(slug => ciResult[slug] = null);
    }
    return ciResult;
  }
  transformCiResult (ciResultResponse) : CiResult {
    ciResultResponse.created_at = new Date(ciResultResponse.created_at);
    let ciResult : CiResult = ciResultResponse;
    ciResult = this.nullifyTopicsIfProfileIncomplete(ciResult);
    if (ciResult?.meta?.ranges_by_cultural_difference_id?.length){
      ciResult.meta.ranges_by_cultural_difference_id = this.sortByRange(ciResult.meta.ranges_by_cultural_difference_id);
    }
    return this.generatePriorities(ciResult);
  };

  transformMetaText (metaText) : MetaText {
    // metaText.created_at = new Date(metaText.created_at);
    let transformedMetaText : MetaText = metaText;
    return transformedMetaText;
  };
  transformCiResults (ciResultsResponse) : CiResult[] {
    let transformedCiResults : CiResult[] = [];
    ciResultsResponse.forEach(cir=>{
      transformedCiResults.push(this.transformCiResult(cir));
    });
    return transformedCiResults;
  }
  transformMetaTexts (metaTextsFromBackend) : MetaText[] {
    let transformedMetaTexts : MetaText[] = [];
    metaTextsFromBackend.forEach(mt=>{
      transformedMetaTexts.push(this.transformMetaText(mt));
    });
    return transformedMetaTexts;
  }
  getCiResults (freshFromServer: boolean){
    return this.http.get<{data: CiResult[]}>('api/v1/survey/ciprofile/feedback')
      .pipe(
        map(response =>{
          if (response ){
            let ciResults = this.transformCiResults(response.data);
            this.cacheCiResults(ciResults);
            return ciResults;
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  }

  getCiResult (snapshot_id: number, freshFromServer : boolean){

    if (!snapshot_id){alert('Pleaes check the id');};
    let cachedResult : CiResult = this.getCachedResultBySnapshot(snapshot_id);
    if (cachedResult && !freshFromServer){
      return of(cachedResult);
    };

    return this.http.get<{data: CiResult[]}>('api/v1/survey/ciprofile/analyse?snapshot='+snapshot_id+'&level=0')
      .pipe(
        map(response =>{
          if (response ){
            let ciResult = this.transformCiResult(response.data);
            this.cacheCiResult(ciResult);
            return ciResult;
          };
        }),
        catchError((error) => {
          return this.handleError(error);
        })
      )
  }

  private getPages(category: string, type: string): Observable<Page[]> {
    return this.pageService.getPagesByCategoryAndType(category, type);
  }

  private requestMetaText(freshFromServer: boolean, page_id: number, metatextable_category: string): Observable<MetaText[]> {
    return this.http.get<{ data: MetaText[] }>(`api/v1/metatexts/page/${page_id}?category=${metatextable_category}`)
      .pipe(
        map(response => this.transformMetaTexts(response.data)),
        catchError((error) => this.handleError(error))
      );
  }

  getMetaText(metatext_category: string, metatext_type: string, freshFromServer: boolean): Observable<MetaText[]> {

    let cachedMetaTexts = this.metaTexts.filter(mt => mt.category == metatext_category);

    if (cachedMetaTexts?.length && !freshFromServer){
      return of(cachedMetaTexts);
    };

    return this.getPages('ciprofile', 'home').pipe(
      switchMap((pages) => {
        let page_id = pages?.[0]?.id;

        if (!page_id){
          throw new Error('Could not get metatextable page id');
        }
        return this.requestMetaText(freshFromServer, page_id, metatext_category);
      })
    );
  }
  getScaleSteps(resultPropertyKey : string , resultPropertyValue : number){
    // minimumScore : number = 50, maximumScore : number = 90, maximumSteps : number = 8
    if (resultPropertyValue !== null && resultPropertyValue !== undefined){
      const minimumScore = resultPropertyKey && this.scoreCalibrationsByProperty[resultPropertyKey] ? this.scoreCalibrationsByProperty[resultPropertyKey].minimumScore : this.scoreCalibrationsByProperty.default.minimumScore;
      const maximumScore = resultPropertyKey && this.scoreCalibrationsByProperty[resultPropertyKey] ? this.scoreCalibrationsByProperty[resultPropertyKey].maximumScore : this.scoreCalibrationsByProperty.default.maximumScore;
      const maximumSteps = resultPropertyKey && this.scoreCalibrationsByProperty[resultPropertyKey] ? this.scoreCalibrationsByProperty[resultPropertyKey].maximumSteps : this.scoreCalibrationsByProperty.default.maximumSteps;
      let $return = this.dataProcessingService.calculateStepsFromPercentScore(resultPropertyValue,minimumScore,maximumScore,maximumSteps);
      return $return
    }
    return 0;
  }
  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    switch (errorResponse.error.message) {
      case 'This data given was invalid.':
        errorMessage = 'error.data_invalid';
        break;
      case 'This action is unauthorized.':
        errorMessage = 'error.permissions_lacking';
        break;
      default :
        // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
