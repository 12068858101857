import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Brand } from './brand.model';
import { BrandDisplayService } from './brand-display.service';
import { DefaultBrandConstants } from './default-brand.constants';
import { ColourService } from './colour.service';
import { BrandResponse } from './brand-response.model';
import { BrandColour } from './brand-colour.model';

export interface BrandEditingTask {
  alert_category_label_translation_key: string // 'hubs.brand'
  situation_translation_key : string, // 'tracking.missing',
  alert_text_translation_key : string, // 'tracking.incomplete_thing',
  status: string, // 'info','warning','danger','success' // These values should map onto the StatusItem.status values
  related_status_item?: string // 'logos'
}

@Injectable({
  providedIn: 'root'
})
export class BrandEditingService {
  subdomain: string;
  defaultBrandConstants;
  _draftBrandEditMode: string; // keeps this in memory for the colour editor, so that it does not revert the default every time the user browses away
  _logo_names : string[];

  constructor(
    private http: HttpClient,
    private brandDisplayService : BrandDisplayService,
    private colourService : ColourService,
  ) { 
    this.colourService = colourService;
    this.defaultBrandConstants = DefaultBrandConstants;
    this._draftBrandEditMode = 'easy';
    this.setSubdomain();
    this.removeOldDraftBrandsFromLocalStorage ();
    this._logo_names = ['logo_banner','logo_square','logo_banner_inverse','logo_square_inverse'];
  }

  get draftBrandEditMode (){
    return this._draftBrandEditMode;
  }
  set draftBrandEditMode (newValue){
    this._draftBrandEditMode = newValue;
  }
  setSubdomain (){
    if (window.location.host.split('.').length>1){
      this.subdomain = window.location.host.split('.')[0];
    };
  }
  clearData (){
    localStorage.removeItem('draftBrands');
  }

  validateBrandColours (brand:Brand) : Brand {

    brand.colours = brand.colours || [];

    let validatedBrandColours : {name:string,hex:string}[] = brand.colours.map(c => {
      return {name:c.name,hex:this.colourService.validateHexColourValue(c.hex)};
    })
    brand.colours = validatedBrandColours;

    return brand;
  }
  deleteBrandFromLocalStorage (slug){
    let storedBrands = this.brandDisplayService.getBrandsFromLocalStorage()
    if (storedBrands?.[slug]){
      delete storedBrands[slug];
      localStorage.removeItem('storedBrands');
      if (Object.keys(storedBrands).length){ // now put back the remaining brands
        for (let brand in storedBrands){
          this.brandDisplayService.putBrandInLocalStorage(storedBrands[brand]);
        }
      }
    }
  }
  deleteDraftBrandFromLocalStorage (savedBrandSlug: string){

    if (!savedBrandSlug){return;};
    let storedDraftBrands : Brand[] = localStorage.getItem('draftBrands') ? JSON.parse(localStorage.getItem('draftBrands')) : [];
    let foundIndex = storedDraftBrands.findIndex(sdb=>sdb.slug+'-edit' === savedBrandSlug);
    if (foundIndex >-1){
      storedDraftBrands.splice(foundIndex,1);
    }
    localStorage.setItem('draftBrands',JSON.stringify(storedDraftBrands));
  }

  applyNewVariantsToBrand (brand : Brand, newVariants : BrandColour[], applyPrimaryColourToLink : boolean){
    if (!brand?.colours?.length){return brand; };
    newVariants.forEach((nv)=>{
      let brandColourIndexes  = brand.colours.map((c,index)=> {
        if ((c.name == nv.name) || (applyPrimaryColourToLink && (nv.name == 'primary' && c.name == 'link') || (nv.name == 'primary-shadow' && c.name == 'link-shadow'))){
          return index;
        }
      }).filter(c=>Number.isInteger(c)/*because 0 is a valid index too*/);
      if (brandColourIndexes?.length){
        brandColourIndexes.forEach(i => {
          brand.colours[i].hex = nv.hex;
        })
      }
    })
    return brand
  }

  copyBrandWithoutReference (sourceBrand : Brand, slug_suffix:string){  // slug_suffix for example '-edit' for draft brands
    if (!sourceBrand){return this.brandDisplayService.getNewDraftBrand();};
    sourceBrand = JSON.parse(JSON.stringify(sourceBrand)); // TODO this should not be necessary, but without it, the returned brand keeps a reference to the new brand. Why?
    return new Brand(
      null,
      sourceBrand.name,
      sourceBrand.slug+slug_suffix,
      sourceBrand.colours,
      sourceBrand.font,
      sourceBrand.slogan,
      sourceBrand.pending,
      sourceBrand.logo_square,
      sourceBrand.logo_banner,
      sourceBrand.logo_square_inverse,
      sourceBrand.logo_banner_inverse,
      null, // TODO - restore sourceBrand.created_at if null causes problems
      null, // TODO - restore sourceBrand.updated_at if null causes problems
      null, // TODO - restore sourceBrand.deleted_at if null causes problems
      null, // loaded_at
      sourceBrand.hubMicro,
      null,
      null, // completion
      sourceBrand.category
    );
  }
  setCompletionStatus (brand : Brand){
    brand.completion = 60;
    const optional_properties = this._logo_names;
    optional_properties.forEach(op=>{
      if (brand[op]){
        brand.completion += 10;
      }
    });
    return brand;
  }
  convertLogoFileFormats(brand:Brand){
    const optional_properties = this._logo_names;
    optional_properties.forEach(op=>{
      if (brand[op]){
        brand[op] = brand[op].replace(/\.(jpg|jpeg|svg)$/, '.png');
      }
      if (brand.pending?.[op]){
        brand.pending[op] = brand.pending[op].replace(/\.(jpg|jpeg|svg)$/, '.png');
      }
    });
    return brand;
  }
  transformBackendBrand (brand){
    let brandColours = this.brandDisplayService.transformBackendColours(brand.colours);
    let savedBrand : Brand = Object.assign(brand,{'colours':brandColours,'loaded_at':new Date()});
    this.brandDisplayService.putBrandInLocalStorage(savedBrand);
    savedBrand.category = brand['pivot']?.['category'];
    savedBrand = this.convertLogoFileFormats(savedBrand);
    return this.setCompletionStatus(savedBrand);
  }
  transformBackendBrands (brands){
    let handledBrands : Brand[] = []; 
    if (brands?.length) {
      handledBrands = brands.map(b=>this.transformBackendBrand(b));
    }
    return handledBrands;
  }
  prepareDraftBrandForSaving (brand : Brand, template_brand_id: number) : FormData{
    brand = this.validateBrandColours(brand)
    let formData = new FormData();

    formData.append('id',brand.id? brand.id.toString():null);
    formData.append('name',brand.name);
    formData.append('slug',brand.slug);
    formData.append('colours',JSON.stringify(brand.colours.map(c=>c.hex)));
    formData.append('font',brand.font);
    formData.append('category',brand.category);
    // formData.append('slogan',brand.slogan); // deprecated
    if(template_brand_id){ // The brand from which we're copying. The backend will remove the pending logos from the template brand.
      formData.append('template_brand_id',template_brand_id.toString());
    }
    const fileKeys = ['logo_banner', 'logo_square', 'logo_banner_inverse', 'logo_square_inverse'];

    for (const key of fileKeys) {
      const file = brand.pending_updates?.[key]?.file;
      const fileUrl = brand.pending_updates?.[key]?.file_url;
      const removeSaved = brand.pending_updates?.[key]?.removeSaved;

      if (removeSaved){
        formData.append(`${key}_remove_saved`, removeSaved); // for example 'logo_banner_remove_saved', 'logo_banner_inverse_remove_saved', 'logo_square_remove_saved', 'logo_square_inverse_remove_saved'
      } else if (file) {
        formData.append(`${key}_file`, file); // for example 'logo_banner_file', 'logo_banner_inverse_file', 'logo_square_file', 'logo_square_inverse_file'
      } else if (fileUrl) {
        formData.append(`${key}_file_url`, fileUrl); // for example 'logo_banner_file_url', 'logo_banner_inverse_file_url', 'logo_square_file_url', 'logo_square_inverse_file_url'
      }
    }
    return formData;
  }
  getBrands(){ // get brands the current user is allowed to edit
    return this.http
    .get<{data:BrandResponse[]}>('api/v1/brand')
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        return this.transformBackendBrands(responseData.data);
      })
    );
  }
  // saveBrandLocally (brand:Brand){ TODO - delete this if commenting it out on 4.7.2023 causes no problems
  //   this.deleteDraftBrandFromLocalStorage (brand.slug);
  //   debugger; // check that draft and display brands have compatible formats or are converted
  //   this.brandDisplayService.putBrandInLocalStorage(brand);
  // };
  putDraftBrandInLocalStorage (brand : Brand){
    if (!brand){return;};
    brand.loaded_at = new Date();
    let storedDraftBrands : Brand[] = localStorage.getItem('draftBrands') ? JSON.parse(localStorage.getItem('draftBrands')) : [];
    let foundIndex = storedDraftBrands.findIndex(sdb=>sdb.slug === brand.slug);
    if (foundIndex >-1){
      storedDraftBrands[foundIndex]=brand;
    } else {
      storedDraftBrands.push(brand);
    }
    localStorage.setItem('draftBrands',JSON.stringify(storedDraftBrands));
  }
  getDraftBrandFromLocalStorage (savedBrand : Brand){
    if (!savedBrand){return;};
    let storedDraftBrands : Brand[] = localStorage.getItem('draftBrands') ? JSON.parse(localStorage.getItem('draftBrands')) : [];
    return storedDraftBrands.find(sdb=>sdb.slug === savedBrand.slug+'-edit');
  }
  removeOldDraftBrandsFromLocalStorage (){
    const timeNow = new Date().getTime();
    let storedDraftBrands : Brand[] = localStorage.getItem('draftBrands') ? JSON.parse(localStorage.getItem('draftBrands')) : [];
    if (storedDraftBrands.length){
      let cleanedStoredDraftBrands : Brand[];
      cleanedStoredDraftBrands = storedDraftBrands.filter(sdb=>sdb.loaded_at && new Date(sdb.loaded_at).getTime()< timeNow-86400000);
      localStorage.setItem('draftBrands',JSON.stringify(cleanedStoredDraftBrands));
    }
  }
  saveNewBrand(brandAsFormData:FormData){
    return this.http
    .post<{data:BrandResponse[]}>('api/v1/brand', brandAsFormData)
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        this.deleteDraftBrandFromLocalStorage (brandAsFormData.get('slug')?.toString());
        return responseData.data; // we'll handle the transformations in the HubService
      })
    );
  }
  saveExistingBrand(brandAsFormData:FormData){ // TODO avoid duplication: this is the same as saveNewBrand except for the HTTP method and the URL
    return this.http
    .post<{data:BrandResponse[]}>('api/v1/brand/'+brandAsFormData.get('id')?.toString(), brandAsFormData)
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        this.deleteDraftBrandFromLocalStorage (brandAsFormData.get('slug')?.toString());
        return responseData.data; // we'll handle the transformations in the HubService
      })
    );
  }
  saveTemporaryLogo(brandAsFormData:FormData){
    return this.http
    .post<{data:BrandResponse[]}>('api/v1/brand/'+brandAsFormData.get('id')?.toString()+'/temporary-logo', brandAsFormData)
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        this.deleteDraftBrandFromLocalStorage (brandAsFormData.get('slug')?.toString());
        return responseData.data; // we'll handle the transformations in the HubService
      })
    );
  }
  removeTemporaryLogos(brand_slug: string, pendingItemKeys:string[]){ // pendingItemKeys: 'banner','square','banner_inverse','square_inverse'
    return this.http
    .post<{data:BrandResponse[]}>('api/v1/brand/'+brand_slug+'/remove-temporary-logos', {pending_item_keys:pendingItemKeys})
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        this.deleteDraftBrandFromLocalStorage (brand_slug);
        return responseData.data; // we'll handle the transformations in the HubService
      })
    );
  }
  setBrandAsPrimary(brand: Brand, hub_id:number){
    return this.http
    .get<{data:BrandResponse[]}>('api/v1/brand/'+brand.id+'/hub/'+hub_id+'/set-primary')
    .pipe(
      catchError(error=>this.handleError(error)),
      map((responseData) => {
        this.deleteDraftBrandFromLocalStorage (brand.slug);
        return responseData.data; // we'll handle the transformations in the HubService
      })
    );
  }
  generateTaskList (brand : Brand){
    let tasks : BrandEditingTask[] = [];
    let logoTasks : BrandEditingTask[] = this.generateLogoTaskList(brand);
    tasks =tasks.concat(logoTasks);
    return this.generateMasterTaskList(tasks);
  }
  generateMasterTaskList (tasks : BrandEditingTask[]){
    let tasksCombinedObject : {
      masterTask : BrandEditingTask,
      tasks: BrandEditingTask[],
    } = {
      masterTask : {
        alert_category_label_translation_key: null, // 'hubs.brand'
        alert_text_translation_key : 'tracking.tasks_awaiting_attention', // 'tracking.incomplete_thing',
        situation_translation_key : null, // 'common.get_details'
        status: 'info', // 'info','warning','danger'
      },
      tasks : tasks
    };

    tasks.forEach(t=>{
      if (tasksCombinedObject.masterTask.status === 'info'){
        if (['warning','danger'].includes(t.status)){
          tasksCombinedObject.masterTask.status = t.status;
        }
      } else if (tasksCombinedObject.masterTask.status === 'warning' && t.status === 'danger'){
        tasksCombinedObject.masterTask.status = t.status;
      }
    });
    return tasksCombinedObject;
  }
  generateLogoTaskList (brand : Brand) : BrandEditingTask[]{
    let brandTasks : BrandEditingTask[] = [];
    this._logo_names.forEach(ln=>{
      if (!brand[ln]){
        brandTasks.push({
          alert_category_label_translation_key : 'branding.logo',
          alert_text_translation_key : 'branding.'+ln+'_description_short',
          situation_translation_key : 'tracking.missing',
          status: ln == 'logo_banner' ? 'danger' : 'warning', // 'info','warning','danger'
        })
      }
    });
    return brandTasks;
  }

  private handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = 'error.something_went_wrong';
    if (!errorResponse.error || !errorResponse.error.message) {
      return throwError(errorMessage);
    }
    if (errorResponse.error.errors?.slug?.[0] === 'The slug has already been taken.'){
      errorMessage ="content_management.slug_availability_error";
      return throwError(errorMessage);
    }
    switch (errorResponse.error.message) {
      case 'This data given was invalid.':
        errorMessage = 'error.data_invalid';
        break;
      case 'This action is unauthorized.':
        errorMessage = 'error.permissions_lacking';
        break;
      default :
        // no action needed. We already set the default error message.
    }
    if (errorResponse.error.meta){
      return throwError({message:errorMessage,meta:errorResponse.error.meta});
    }
    return throwError(errorMessage);
  }
}
